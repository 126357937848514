import { Select } from 'ant-design-vue'

function filterFun (inputValue, option) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
}

export default {
    functional: true,
    render (h, context) {
        const { data, children, props, listeners } = context
        return h(Select, {
            ...data,
            props: {
                ...props,
                showSearch: true,
                filterOption: props.filterOption || filterFun
            },
            on: {
                ...listeners
            }
        }, children)
    }
}

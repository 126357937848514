<template>
  <div class="find-someone-wrapper">
    <div class="com-header">
      <div class="nav-box">
        <tabNav :navList="navList" v-model="type" @input="handleTypeChange" />
      </div>
      <a-cascader
        style="margin-right: 12px"
        :options="areaOptions"
        placeholder="请选择区域"
        changeOnSelect
        v-model="postArea"
        @change="handleChangeArea"
        :fieldNames="{
          label: 'district',
          value: 'id',
          children: 'children'
        }" />
      <a-select
        v-model="positionType"
        :placeholder="`请选择${type === '1' ? '工种' : '专业'}搜索`"
        style="width: 220px;"
        @change="handleChange"
        allowClear
      >
        <a-select-option v-for="item in posiList" :key="item.id" :value="item.id">
          {{ item.majorName }}
        </a-select-option>
      </a-select>
    </div>
    <div class="content_list" style="height: 570px; padding: 30px 24px 15px">
      <v-card-list
        ref="cardList"
        :getDataApi="getDataApi"
        :renderItem="renderItem"
        :initPagination="pagination"
        :colConfig="{
          span: 6,
        }"
      >
        <a-empty class="empty" slot="empty" />
        <!-- <template slot="pagination" slot-scope="pagination">
          <div class="custom-pagination">
            <div
              :class="{
                prev: true,
                'pagination-item': true,
                'pagination-item-disabled': pagination.current === 1,
              }"
              @click="$refs.cardList.handlePrev()"
            >
              <a-icon type="left" />
            </div>
            <div
              :class="{
                prev: true,
                'pagination-item': true,
                'pagination-item-disabled': pagination.current * pagination.pageSize >= pagination.total,
              }"
              @click="$refs.cardList.handleNext()"
            >
              <a-icon type="right" />
            </div>
          </div>
        </template> -->
      </v-card-list>
    </div>
    <modalDetails ref="modalDetails" />
  </div>
</template>

<script>
import tabNav from '@/views/home/components/tab-nav.vue'
import itemUser from './item.vue'
import { queryUserData } from '@/api/recruitUse'
import { employmentEmployDictPosition } from '@/api/workDemand'
import modalDetails from './modal-details.vue'
import { getTree } from '@/api/location'
const workObj = {
  1: '30万机组以下',
  2: '30-60万机组',
  3: '100万及以上机组'
}

const levelObj = {
  0: '无',
  1: '初级',
  2: '中级',
  3: '高级',
  '-1': '特殊工种证书'
}

const qualObj = {
  0: '无',
  1: '安全员',
  2: '建造师'
}

export default {
  components: {
    tabNav,
    itemUser,
    modalDetails
  },
  data () {
    return {
      workObj,
      levelObj,
      qualObj,
      navList: [
        {
          label: '技能人员',
          value: '1'
        },
        {
          label: '技术人员',
          value: '2'
        }
      ],
      type: '1',
      positionType: undefined,
      posiList1: [],
      posiList2: [],
      pagination: {
        current: 1,
        pageSize: 12,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: false,
        showTotal: (total) => `总数 ${total} 条`
      },
      areaOptions: [],
      postArea: [],
      selectedArr: []
    }
  },
  computed: {
    posiList () {
      return this.type === '1' ? this.posiList1 : this.posiList2
    }
  },
  created () {
    employmentEmployDictPosition({
      type: 1
    }).then((res) => {
      this.posiList1 = res.data
    })
    employmentEmployDictPosition({
      type: 2
    }).then((res) => {
      this.posiList2 = res.data
    })
    getTree().then((res) => {
      this.areaOptions = res
    })
  },
  methods: {
    filterOption (value, option) {
      console.log(value, option)
    },
    handleChangeArea (value, selectedOptions) {
      this.selectedArr = selectedOptions || []
      this.$refs.cardList.pageInit()
    },
    handleChange () {
      this.$refs.cardList.pageInit()
    },
    handleTypeChange (value) {
      this.type = value
      this.positionType = undefined
      this.$refs.cardList.pageInit()
    },
    getDataApi (params) {
      const positionName = this.posiList.find((item) => item.id === this.positionType)?.majorName
      const area = this.selectedArr.map((item) => item.district).join('-')
      return queryUserData({
        ...params,
        pageSize: 12,
        positionName,
        area,
        type: this.type
      })
    },
    handleOpenDetails (data, type) {
      this.$refs.modalDetails.show(data, type)
    },
    renderItem ({ item }) {
      return (
        <div onClick={this.handleOpenDetails.bind(null, item, this.type)}>
          <itemUser item={item} type={this.type} />
        </div>
      )
    }
  }
}
</script>

<style lang="less">
.find-someone-wrapper {
  .com-header {
    display: flex;
    align-items: center;
    height: 84px;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding: 0 24px;
    .nav-box {
      height: 100%;
      flex: 1;
    }
  }
  .custom-pagination {
    display: flex;
    align-items: center;
    .pagination-item {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d9d9d9;
      outline: none;
      border-radius: 4px;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        border-color: #40a9ff;
        .anticon {
          color: #40a9ff;
        }
      }
      &-disabled {
        cursor: not-allowed;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        &:hover {
          border-color: #d9d9d9;
          .anticon {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
    .prev {
      margin-right: 12px;
    }
  }
}
</style>

<template>
  <div class="train-user">
    <div class="item_title">
      <div class="item_titleline"></div>
      培训统计
    </div>
    <div class="content">
      <a-form-model
        ref="form"
        :model="form"
        class="search_form page-form-search"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        labelAlign="left"
      >
        <a-row class="row_item">
          <a-col :span="6">
            <a-form-model-item label="姓名">
              <a-input v-model="form.realName" placeholder="请输入姓名" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="账号">
              <a-input v-model="form.username" placeholder="请输入账号" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <div class="form_btn_box" v-perms="'search'">
              <a-button type @click="handleReset">重置</a-button>
              <a-button type="primary" style="margin-left: 12px" @click="handleSearch">搜索</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="table-content">
        <p-table
          ref="table"
          row-key="id"
          :columns="columns"
          :sourceData="getDataApi"
          :scorll="{ y: 0 }"
          extraHeight="400"
        >
          <template slot="action" slot-scope="text, record">
            <a-button type="link" @click="handleOpenPlan(record)" v-perms="'details'">查看详情</a-button>
          </template>
        </p-table>
      </div>
    </div>
    <staticDetails ref="staticDetails"/>
  </div>
</template>
<script>
import { getTrainStatic, getTrainPerms } from '@/api/train'
import staticDetails from './static-details.vue'

export default {
  components: {
    staticDetails
  },
  data () {
    return {
      form: {},
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          key: 'realName'
        },
        {
          title: '账号',
          dataIndex: 'username',
          key: 'username'
        },
        {
          title: '培训时长',
          dataIndex: 'duration',
          key: 'duration'
        },
        {
          title: '累计培训总时长',
          dataIndex: 'durationAll',
          key: 'durationAll'
        },
        {
          title: '最后考试成绩',
          dataIndex: 'score',
          key: 'score'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 200,
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      curPerms: null
    }
  },
  computed: {
    isPerms () {
      if (this.curPerms) {
        return this.curPerms.status != 0
      }
      return false
    }
  },
  created () {
    this.handleGetPerms()
  },
  methods: {
    handleGetPerms () {
      getTrainPerms().then((res) => {
        if (res.data.length) {
          this.curPerms = res.data[0]
          this.$nextTick(() => {
            this.$refs.table.refresh()
          })
        }
      })
    },
    handleSearch () {
      this.$refs.table.initPage()
    },
    handleReset () {
      this.$set(this, 'form', {})
    },
    getDataApi (params) {
      if (this.isPerms) {
        return getTrainStatic({
        ...params,
        ...this.form,
        enterpriseId: this.$store.getters.enterpriseId
      })
      }
      return Promise.resolve({})
    },
    handleOpenPlan (rowItem) {
      this.$refs.staticDetails.createModel(rowItem)
    }
  }
}
</script>
<style lang="less">
.train-user {
  .content {
    background: #ffffff;
    padding: 20px;
    overflow: hidden;
  }
  .form_btn_box {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 40px;
  }
}
</style>
